<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">Total Guests</div>
    <div class="
        col-lg-6 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
      <button type="button" @click="voterFilterModalOpen()" class="header-filter-btn-outer btn btn-light" title="Filter">
        <span class="custom-filter-apply-alert"></span>
        <i class="pi pi-filter-fill custom-text-primary" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link active" id="voter-all-details-tab" data-bs-toggle="tab" data-bs-target="#voteralldetail"
        type="button" role="tab" aria-controls="vehicle-details" aria-selected="true">
        Live Guest Details
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="voter-static-tab" data-bs-toggle="tab" data-bs-target="#voterstatic" type="button"
        role="tab" aria-controls="customer-details" aria-selected="false" @click="redirectdynvisitor">
        Guest
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="voter-report-tab" data-bs-toggle="tab" data-bs-target="#voterreport" type="button"
        role="tab" aria-controls="customer-details" aria-selected="false">
        Reports
      </button>
    </li>
  </ul>
  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="voteralldetail" role="tabpanel" aria-labelledby="voter-all-details-tab">
      <div class="company-section-outer" style="height: calc(100vh - 180px);">
        <div class="layout-content-section">
          <div class="company-detail-box-outer border-guest-international mt-3">
            <div class="row g-2">
              <div class="
              col-lg-2 col-md-2 col-12
              voter-profile-col
              d-flex
              align-items-center
            ">
                <div class="customer-label-group">
                  <div class="d-flex align-items-center flex-column">
                    <div>
                      <img src="/assets/images/profile-icon-3.svg" class="rounded bg-light me-auto d-block" alt=""
                        width="72" />
                    </div>
                    <div class="text-primary font-weight-bold mt-2">+3</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-2 col-12 voter-name-col">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Guest Name</label>
                      <div class="from-label-value">Mr. Mike Lowy</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Gendar | Blood Group</label>
                      <div class="from-label-value">Male | B-ve</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Country | State | City</label>
                      <div class="from-label-value">Australia | Victoria | Melbourne</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-2 col-12">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Date of Birth | Age</label>
                      <div class="from-label-value">23/03/1983 | 40</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Date of Entry</label>
                      <div class="from-label-value">20/08/2023</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Duration of Stay</label>
                      <div class="from-label-value">1 Day</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-12">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Visa Expiry Date</label>
                      <div class="from-label-value">21/09/2023</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Guest Type</label>
                      <div class="from-label-value">Tourist</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Host</label>
                      <div class="from-label-value">Hotel (Sahara Hotel)</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-12">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Last Updated Date Time</label>
                      <div class="from-label-value">20/08/2023 10:45 PM</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2 d-flex justify-content-between">
                      <div>
                        <label class="form-label">Mobile</label>
                        <div class="from-label-value">
                          +61-688XXXX372
                        </div>
                      </div>
                      <div class="d-flex align-items-end">
                        <button class="btn custom-outline-call-btn mx-2">
                          <i class="fas fa-phone-alt call-btn-color"></i></button>
                        <button type="button" title="View Details" class="btn custom-outline-view-btn me-2"
                          @click="VisitorInternationalDetailsModalOpen()">
                          <i class="pi pi-pencil call-btn-color"></i>
                        </button>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="customer-label-group ">
                        <label class="form-label">Criminal Record Status</label>
                        <div class="from-label-value">
                          N/A
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="company-detail-box-outer border-guest-domestic mt-3">
            <div class="row g-2">
              <div class="
              col-lg-2 col-md-2 col-12
              voter-profile-col
              d-flex
              align-items-center
            ">
                <div class="customer-label-group">
                  <div class="d-flex align-items-center">
                    <div>
                      <img src="/assets/images/profile-icon-1.svg" class="alerts-border rounded bg-light me-auto d-block"
                        alt="" width="72" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-2 col-12 voter-name-col">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Guest Name</label>
                      <div class="from-label-value">Mr. Dinesh Kumar</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Gendar | Blood Group</label>
                      <div class="from-label-value">Male | B+ve</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Country | State | City</label>
                      <div class="from-label-value">India | Madhya Pradesh | Bhopal</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-2 col-12">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Date of Birth | Age</label>
                      <div class="from-label-value">03/08/1981 | 42</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Date of Entry</label>
                      <div class="from-label-value">16/08/2023</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Duration of Stay</label>
                      <div class="from-label-value">7 Days</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-12">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Visa Expiry Date</label>
                      <div class="from-label-value">N/A</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Guest Type</label>
                      <div class="from-label-value">Tourist</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Host</label>
                      <div class="from-label-value">Hotel (Sky Hotel)</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-12">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Last Updated Date Time</label>
                      <div class="from-label-value">27/08/2023 10:38 AM</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2 d-flex justify-content-between">
                      <div>
                        <label class="form-label">Mobile</label>
                        <div class="from-label-value">
                          +91-883XXXX272
                        </div>
                      </div>
                      <div class="d-flex align-items-end">
                        <button class="btn custom-outline-call-btn mx-2">
                          <i class="fas fa-phone-alt call-btn-color"></i></button>
                        <button disabled type="button" title="View Details" class="btn custom-outline-view-btn me-2">
                          <i class="pi pi-pencil call-btn-color"></i>
                        </button>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="customer-label-group ">

                        <label class="form-label">Criminal Record Status</label>
                        <div class="from-label-value blink-visa-expired">
                          Type 1
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="company-detail-box-outer border-guest-domestic mt-3">
            <div class="row g-2">
              <div class="
              col-lg-2 col-md-2 col-12
              voter-profile-col
              d-flex
              align-items-center
            ">
                <div class="customer-label-group">
                  <div class="d-flex align-items-center flex-column">
                    <div>
                      <img src="/assets/images/profile-icon-2.svg" class="rounded bg-light me-auto d-block" alt=""
                        width="72" />
                    </div>
                    <div class="text-primary font-weight-bold mt-2">+2</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-2 col-12 voter-name-col">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Guest Name</label>
                      <div class="from-label-value">Ms. Varsha Mane</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Gendar | Blood Group</label>
                      <div class="from-label-value">Female | B+ve</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Country | State | City</label>
                      <div class="from-label-value">India | Maharashtra | Pune</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-2 col-12">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Date of Birth | Age</label>
                      <div class="from-label-value">03/08/1991 | 32</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Date of Entry</label>
                      <div class="from-label-value">20/08/2023</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Duration of Stay</label>
                      <div class="from-label-value">3 Days</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-12">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Visa Expiry Date</label>
                      <div class="from-label-value">N/A</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Guest Type</label>
                      <div class="from-label-value">Work Traveller</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Host</label>
                      <div class="from-label-value">Hotel (Royal Hotel)</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-12">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Last Updated Date Time</label>
                      <div class="from-label-value">20/08/2023 10:38 AM</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2 d-flex justify-content-between">
                      <div>
                        <label class="form-label">Mobile</label>
                        <div class="from-label-value">
                          +91-983XXXX213
                        </div>
                      </div>
                      <div class="d-flex align-items-end">
                        <button class="btn custom-outline-call-btn mx-2">
                          <i class="fas fa-phone-alt call-btn-color"></i></button>
                        <button type="button" title="View Details" class="btn custom-outline-view-btn me-2"
                          @click="VisitorDomesticDetailsModalOpen()">
                          <i class="pi pi-pencil call-btn-color"></i>
                        </button>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="customer-label-group ">

                        <label class="form-label">Criminal Record Status</label>
                        <div class="from-label-value">
                          N/A
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="company-detail-box-outer border-guest-international">
            <div class="row g-2">
              <div class="
              col-lg-2 col-md-2 col-12
              voter-profile-col
              d-flex
              align-items-center
            ">
                <div class="customer-label-group">
                  <div class="d-flex align-items-center">
                    <div>
                      <img src="/assets/images/profile-icon-1.svg" class="rounded bg-light me-auto d-block" alt=""
                        width="72" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-2 col-12 voter-name-col">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Guest Name</label>
                      <div class="from-label-value">Mr. Dietmar Otto</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Gendar | Blood Group</label>
                      <div class="from-label-value">Male | AB-ve</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Country | State | City</label>
                      <div class="from-label-value">Germany | Berlin | Berlin</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-2 col-12">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Date of Birth | Age</label>
                      <div class="from-label-value">17/05/1988 | 35</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Date of Entry</label>
                      <div class="from-label-value">31/05/2021</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Duration of Stay</label>
                      <div class="from-label-value">2 Years</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-12">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Visa Expiry Date</label>
                      <div class="from-label-value blink-visa-expired">24/07/2023</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Guest Type</label>
                      <div class="from-label-value">Student</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Host</label>
                      <div class="from-label-value">Woke Hostel (Panaji)</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-12">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2">
                      <label class="form-label">Last Updated Date Time</label>
                      <div class="from-label-value">20/08/2023 10:38 AM</div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group mb-2 d-flex justify-content-between">
                      <div>
                        <label class="form-label">Mobile</label>
                        <div class="from-label-value">
                          +49-783XXXX332
                        </div>
                      </div>
                      <div class="d-flex align-items-end">
                        <button class="btn custom-outline-call-btn mx-2">
                          <i class="fas fa-phone-alt call-btn-color"></i></button>
                        <button disabled type="button" title="View Details" class="btn custom-outline-view-btn me-2">
                          <i class="pi pi-pencil call-btn-color"></i>
                        </button>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="customer-label-group ">

                        <label class="form-label">Criminal Record Status</label>
                        <div class="from-label-value">
                          N/A
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-pane fade" id="voterreport" role="tabpanel" aria-labelledby="voter-report-tab">
      <div class="company-section-outer py-3" style="height: calc(100vh - 180px)">
        <div class="row g-3">
          <div class="col-lg-4 col-md-4 col-12">
            <div class="feedback-chart-outer pb-3">
              <div class="chart-label">Guest Type Wise Report</div>
              <div>
                <Chart type="doughnut" :data="chartDataGender" :options="lightOptions" responsive="true" />
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <div class="feedback-chart-outer pb-3">
              <div class="chart-label">Criminal Records Wise Report</div>
              <div>
                <Chart type="doughnut" :data="chartDataProfession" :options="lightOptions" responsive="true" />
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <div class="feedback-chart-outer pb-3">
              <div class="chart-label">Gender Wise Report</div>
              <div>
                <Chart type="doughnut" :data="chartDataBloodGroup" :options="lightOptions" responsive="true" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-pane fade" id="voterstatic" role="tabpanel" aria-labelledby="voter-static-tab"></div>
  </div>
  <!-- filter start here -->
  <div class="modal-mask" v-if="voterfiltermodalsflag">
    <div class="
        modal-md modal-dialog-scrollable modal-fullscreen
        ms-auto
        custom-modal-outer
      ">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Filter</h5>
          <button type="button" class="btn-close" @click="voterFilterModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Guest Name</label>
                    <input type="text" v-model="visitorname" class="form-control" placeholder="Enter Name" maxlength="30"
                      autocomplete="off" />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Country</label>
                    <Multiselect v-model="country" :options="countryList" :searchable="true" label="label"
                      placeholder="Select" class="multiselect-custom text-capitalize" :canClear="true"
                      :closeOnSelect="true" />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">State</label>
                    <Multiselect v-model="state" :options="stateList" :searchable="true" label="label"
                      placeholder="Select" class="multiselect-custom text-capitalize" :canClear="true"
                      :closeOnSelect="true" />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">City</label>
                    <Multiselect v-model="city" :options="cityList" :searchable="true" label="label" placeholder="Select"
                      class="multiselect-custom text-capitalize" :canClear="true" :closeOnSelect="true" />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Blood Group</label>
                    <Multiselect v-model="bloodgroup" :options="bloodList" :searchable="true" label="label"
                      placeholder="Select" class="multiselect-custom text-capitalize" :canClear="true"
                      :closeOnSelect="true" />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Gender</label>
                    <div class="custom-group-radio-box-btn">
                      <input type="radio" class="btn-check" v-model="gender" name="gender-option" id="male"
                        autocomplete="off" value="1" />
                      <label class="btn custom-radio-box-btn text-capitalize" for="male">Male</label>
                      <input type="radio" class="btn-check" v-model="gender" name="gender-option" id="female"
                        autocomplete="off" value="2" />
                      <label class="btn custom-radio-box-btn text-capitalize" for="female">Female</label>
                      <input type="radio" class="btn-check" v-model="gender" name="gender-option" id="other"
                        autocomplete="off" value="3" />
                      <label class="btn text-capitalize" for="other">Other</label>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Age</label>
                    <Multiselect v-model="age" :options="ageList" :searchable="true" label="label" placeholder="Select"
                      class="multiselect-custom text-capitalize" :canClear="true" :closeOnSelect="true" />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Criminal Record Status</label>
                    <div class="custom-group-radio-box-btn">
                      <input type="radio" class="btn-check" v-model="criminal" name="criminal-option" id="type1"
                        autocomplete="off" value="1" />
                      <label class="btn custom-radio-box-btn text-capitalize" for="type1">Type 1</label>
                      <input type="radio" class="btn-check" v-model="criminal" name="criminal-option" id="type2"
                        autocomplete="off" value="2" />
                      <label class="btn custom-radio-box-btn text-capitalize" for="type2">Type 2</label>
                      <input type="radio" class="btn-check" v-model="criminal" name="criminal-option" id="type3"
                        autocomplete="off" value="3" />
                      <label class="btn text-capitalize" for="type3">Type 3</label>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Guest Type</label>
                    <Multiselect v-model="visitortype" :options="visitortypeList" :searchable="true" label="label"
                      placeholder="Select" class="multiselect-custom text-capitalize" :canClear="true"
                      :closeOnSelect="true" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 m-0">
            <div class="d-flex justify-content-between">
              <button class="btn filter-apply-btn">
                Apply Filter
              </button>
              <button class="btn btn-link filter-reset-btn">
                Reset All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- filter end here -->
  <!-- view detail International modal start here -->
  <div class="modal-mask" v-if="viewvisitorinternationalstatus">
    <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header custom-custmer-header-border">
          <h5 class="modal-title" id="staticBackdropLabel">Guest History</h5>
          <button type="button" class="btn-close" @click="VisitorInternationalDetailsModalClose()"></button>
        </div>
        <ul class="nav nav-tabs custom-customer-modal-tab" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="visitor-int-passport-tab" data-bs-toggle="tab"
              data-bs-target="#visitorintpassportdetails" type="button" role="tab" aria-controls="vehicle-details"
              aria-selected="true">
              Passport
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="visitor-int-checkin-tab" data-bs-toggle="tab"
              data-bs-target="#visitorintcheckindetails" type="button" role="tab" aria-controls="appointment-history"
              aria-selected="false">
              Check Ins
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="visitor-int-cotravel-tab" data-bs-toggle="tab"
              data-bs-target="#visitorintcotraveldetails" type="button" role="tab" aria-controls="appointment-history"
              aria-selected="false">
              Co Travellers
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="visitor-int-complaints-tab" data-bs-toggle="tab"
              data-bs-target="#visitorintcompaintsdetails" type="button" role="tab" aria-controls="appointment-history"
              aria-selected="false">
              Complaints
            </button>
          </li>
        </ul>
        <div class="modal-body modal-body-scroll-outer postion-relative">
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="visitorintpassportdetails" role="tabpanel"
              aria-labelledby="visitor-int-passport-tab">
              <img src="/assets/images/Greek_passport_biodata_page.png" class="d-block" alt="" width="300" />
              <img src="/assets/images/Back-Cover.jpg" class="d-block mt-4" alt="" width="300" />
            </div>
            <div class="tab-pane fade" id="visitorintcheckindetails" role="tabpanel"
              aria-labelledby="visitor-int-checkin-tab">
              <div class="voter-exit-box-outer mb-3">
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Guest Name</label>
                          <div class="from-label-value text-capitalize">
                            Mr. Mike Lowy
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Host Type</label>
                          <div class="from-label-value">
                            Hotel
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Check In Date</label>
                          <div class="from-label-value text-capitalize">
                            20/08/2023 10:29 AM
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Host Name</label>
                          <div class="from-label-value">
                            Sahara Hotel Panjim
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Check Out Date</label>
                          <div class="from-label-value text-capitalize">
                            23/08/2023 12:00 PM
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Duration of Stay</label>
                          <div class="from-label-value text-capitalize">
                            1 Days
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="visitorintcotraveldetails" role="tabpanel"
              aria-labelledby="visitor-int-cotravel-tab">
              <div class="voter-exit-box-outer mb-3">
                <div class="row">
                  <div class="
              col-lg-2 col-md-2 col-12
              d-flex
              align-items-center
            ">
                    <div class="customer-label-group">
                      <div class="d-flex align-items-center flex-column">
                        <img src="/assets/images/profile-icon-3.svg" class="rounded bg-light me-auto d-block" alt=""
                          width="72" />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Guest Name</label>
                          <div class="from-label-value text-capitalize">
                            Mr. Mike Lowy
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Host Type</label>
                          <div class="from-label-value">
                            Hotel
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-12">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Check In Date</label>
                          <div class="from-label-value text-capitalize">
                            20/08/2023 10:29 AM
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Host Name</label>
                          <div class="from-label-value">
                            Sahara Hotel Panjim
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-12">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Check Out Date</label>
                          <div class="from-label-value text-capitalize">
                            23/08/2023 12:00 PM
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Duration of Stay</label>
                          <div class="from-label-value text-capitalize">
                            1 Days
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="voter-exit-box-outer mb-3">
                <div class="row">
                  <div class="
              col-lg-2 col-md-2 col-12
              d-flex
              align-items-center
            ">
                    <div class="customer-label-group">
                      <div class="d-flex align-items-center flex-column">
                        <img src="/assets/images/profile-icon-2.svg" class="rounded bg-light me-auto d-block" alt=""
                          width="72" />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Guest Name</label>
                          <div class="from-label-value text-capitalize">
                            Mr. Eliza Lowy
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Host Type</label>
                          <div class="from-label-value">
                            Hotel
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-12">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Check In Date</label>
                          <div class="from-label-value text-capitalize">
                            20/08/2023 10:29 AM
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Host Name</label>
                          <div class="from-label-value">
                            Sahara Hotel Panjim
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-12">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Check Out Date</label>
                          <div class="from-label-value text-capitalize">
                            23/08/2023 12:00 PM
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Duration of Stay</label>
                          <div class="from-label-value text-capitalize">
                            1 Days
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="voter-exit-box-outer mb-3">
                <div class="row">
                  <div class="
              col-lg-2 col-md-2 col-12
              d-flex
              align-items-center
            ">
                    <div class="customer-label-group">
                      <div class="d-flex align-items-center flex-column">
                        <img src="/assets/images/profile-icon-3.svg" class="rounded bg-light me-auto d-block" alt=""
                          width="72" />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Guest Name</label>
                          <div class="from-label-value text-capitalize">
                            Mr. Ryan Lowy
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Host Type</label>
                          <div class="from-label-value">
                            Hotel
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-12">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Check In Date</label>
                          <div class="from-label-value text-capitalize">
                            20/08/2023 10:29 AM
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Host Name</label>
                          <div class="from-label-value">
                            Sahara Hotel Panjim
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-12">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Check Out Date</label>
                          <div class="from-label-value text-capitalize">
                            23/08/2023 12:00 PM
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Duration of Stay</label>
                          <div class="from-label-value text-capitalize">
                            1 Days
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="visitorintcompaintsdetails" role="tabpanel"
              aria-labelledby="visitor-int-complaints-tab">
              No Records Found
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- view detail International modal end here -->
  <!-- view detail Domestic modal start here -->
  <div class="modal-mask" v-if="viewvisitordomesticstatus">
    <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header custom-custmer-header-border">
          <h5 class="modal-title" id="staticBackdropLabel">Guest History</h5>
          <button type="button" class="btn-close" @click="VisitorDomesticDetailsModalClose()"></button>
        </div>
        <ul class="nav nav-tabs custom-customer-modal-tab" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="visitor-dom-aadhar-tab" data-bs-toggle="tab"
              data-bs-target="#visitordomaadhardetails" type="button" role="tab" aria-controls="vehicle-details"
              aria-selected="true">
              Aadhaar
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="visitor-dom-checkin-tab" data-bs-toggle="tab"
              data-bs-target="#visitordomcheckindetails" type="button" role="tab" aria-controls="appointment-history"
              aria-selected="false">
              Check Ins
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="visitor-dom-cotravel-tab" data-bs-toggle="tab"
              data-bs-target="#visitordomcotraveldetails" type="button" role="tab" aria-controls="appointment-history"
              aria-selected="false">
              Co Travellers
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="visitor-dom-complaints-tab" data-bs-toggle="tab"
              data-bs-target="#visitordomcompaintsdetails" type="button" role="tab" aria-controls="appointment-history"
              aria-selected="false">
              Complaints
            </button>
          </li>
        </ul>
        <div class="modal-body modal-body-scroll-outer postion-relative">
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="visitordomaadhardetails" role="tabpanel"
              aria-labelledby="visitor-dom-aadhar-tab">
              <div class="row">
                <div class="col-lg-2 col-md-2 col-12 d-flex align-items-center">
                  <div class="customer-label-group">
                    <div class="d-flex align-items-center">
                      <div>
                        <img src="/assets/images/profile-icon-2.svg" class="rounded bg-light me-auto d-block" alt=""
                          width="90" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Name</label>
                        <div class="from-label-value text-capitalize">
                          Ms. Varsha Mane
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Aadhaar Number</label>
                        <div class="from-label-value">
                          9873666473
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Father's Name</label>
                        <div class="from-label-value text-capitalize">
                          Mr. Mane
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="customer-label-group mb-2">
                    <label class="form-label">Date of Birth</label>
                    <div class="from-label-value">
                      03/08/1991
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="customer-label-group mb-2">
                    <label class="form-label">Gender</label>
                    <div class="from-label-value">
                      Female
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="customer-label-group mb-2">
                    <label class="form-label">House</label>
                    <div class="from-label-value text-capitalize">
                      Flat No 422
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="customer-label-group mb-2">
                    <label class="form-label">Landmark</label>
                    <div class="from-label-value text-capitalize">
                      Near ABC Raod
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="customer-label-group mb-2">
                    <label class="form-label">Street</label>
                    <div class="from-label-value text-capitalize">
                      Nagar Road
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="customer-label-group mb-2">
                    <label class="form-label">Location</label>
                    <div class="from-label-value text-capitalize">
                      Pune
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="customer-label-group mb-2">
                    <label class="form-label">Post Office</label>
                    <div class="from-label-value text-capitalize">
                      Pune
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="customer-label-group mb-2">
                    <label class="form-label">Area</label>
                    <div class="from-label-value text-capitalize">
                      Pune
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="customer-label-group mb-2">
                    <label class="form-label">City</label>
                    <div class="from-label-value text-capitalize">
                      Pune
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="customer-label-group mb-2">
                    <label class="form-label">Sub Dist</label>
                    <div class="from-label-value text-capitalize">
                      N/A
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="customer-label-group mb-2">
                    <label class="form-label">State</label>
                    <div class="from-label-value text-capitalize">
                      Maharashtra
                    </div>
                  </div>
                </div>
                <div class="col-lg-8 col-md-8 col-12">
                  <div class="customer-label-group mb-2">
                    <label class="form-label">Address</label>
                    <div class="from-label-value text-capitalize">
                      Address
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="customer-label-group mb-2">
                    <label class="form-label">Pincode</label>
                    <div class="from-label-value">
                      411045
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="visitordomcheckindetails" role="tabpanel"
              aria-labelledby="visitor-dom-checkin-tab">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="voter-exit-box-outer mb-3">
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Guest Name</label>
                            <div class="from-label-value text-capitalize">
                              Mrs. Varsha Mane
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Host Type</label>
                            <div class="from-label-value">
                              Hotel
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Check In Date</label>
                            <div class="from-label-value text-capitalize">
                              21/08/2023 10:29 AM
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Host Name</label>
                            <div class="from-label-value">
                              Royal Hotel Panjim
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Check Out Date</label>
                            <div class="from-label-value text-capitalize">
                              23/08/2023 12:00 PM
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Duration of Stay</label>
                            <div class="from-label-value text-capitalize">
                              3 Days
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="voter-exit-box-outer mb-3">
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Guest Name</label>
                            <div class="from-label-value text-capitalize">
                              Ms. Varsha Mane
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Host Type</label>
                            <div class="from-label-value">
                              Car Rental
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Check In Date</label>
                            <div class="from-label-value text-capitalize">
                              22/08/2023 11:00 AM
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Host Name</label>
                            <div class="from-label-value">
                              Express Cars
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="visitordomcotraveldetails" role="tabpanel"
              aria-labelledby="visitor-dom-cotravel-tab">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="voter-exit-box-outer mb-3">
                  <div class="row">
                    <div class="
              col-lg-2 col-md-2 col-12
              d-flex
              align-items-center
            ">
                      <div class="customer-label-group">
                        <div class="d-flex align-items-center flex-column">
                          <img src="/assets/images/profile-icon-2.svg" class="rounded bg-light me-auto d-block" alt=""
                            width="72" />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Guest Name</label>
                            <div class="from-label-value text-capitalize">
                              Mrs. Varsha Mane
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Host Type</label>
                            <div class="from-label-value">
                              Hotel
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Check In Date</label>
                            <div class="from-label-value text-capitalize">
                              21/08/2023 10:29 AM
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Host Name</label>
                            <div class="from-label-value">
                              Royal Hotel Panjim
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Check Out Date</label>
                            <div class="from-label-value text-capitalize">
                              23/08/2023 12:00 PM
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Duration of Stay</label>
                            <div class="from-label-value text-capitalize">
                              3 Days
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="voter-exit-box-outer mb-3">
                  <div class="row">
                    <div class="
              col-lg-2 col-md-2 col-12
              d-flex
              align-items-center
            ">
                      <div class="customer-label-group">
                        <div class="d-flex align-items-center flex-column">
                          <img src="/assets/images/profile-icon-3.svg" class="rounded bg-light me-auto d-block" alt=""
                            width="72" />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Guest Name</label>
                            <div class="from-label-value text-capitalize">
                              Mr. Vashu Rahane
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Host Type</label>
                            <div class="from-label-value">
                              Car Rental
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Check In Date</label>
                            <div class="from-label-value text-capitalize">
                              21/08/2023 10:29 AM
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Host Name</label>
                            <div class="from-label-value">
                              Express Cars
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Check Out Date</label>
                            <div class="from-label-value text-capitalize">
                              23/08/2023 12:00 PM
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Duration of Stay</label>
                            <div class="from-label-value text-capitalize">
                              3 Days
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="visitordomcompaintsdetails" role="tabpanel"
              aria-labelledby="visitor-dom-complaints-tab">
              No Records Found
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- view detail Domestic modal end here -->
</template>
<script>
export default {
  data() {
    return {
      voterfiltermodalsflag: false,
      chartDataGender: {
        labels: ['Student', 'Tourist', 'Work Traveller'],
        datasets: [
          {
            data: [1, 2, 1],
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          },
        ],
      },
      chartDataProfession: {
        labels: ['Type 1', 'Type 2', 'Type 3'],
        datasets: [
          {
            data: [1, 0, 0],
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          },
        ],
      },
      chartDataBloodGroup: {
        labels: ['Male', 'Female', 'Other'],
        datasets: [
          {
            data: [3, 1, 0],
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          },
        ],
      },
      lightOptions: {
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
      },
      viewvisitorinternationalstatus: false,
      viewvisitordomesticstatus: false,
      visitorname: '',
      country: '',
      countryList: [],
      state: '',
      stateList: [],
      city: '',
      cityList: [],
      bloodgroup: '',
      bloodList: [{ label: "A+ve", value: 1 },
      { label: "B+ve", value: 2 },
      { label: "A-ve", value: 3 },
      { label: "B-ve", value: 4 },
      { label: "AB+ve", value: 5 },
      { label: "AB-ve", value: 6 },
      { label: "O+ve", value: 7 },],
      gender: '',
      age: '',
      ageList: [{ label: "1-15 Years", value: 1 },
      { label: "15-20 Years", value: 2 },
      { label: "21-30 Years", value: 3 },
      { label: "31-50 Years", value: 4 },
      { label: "51-60 Years", value: 5 },
      { label: "61-80 Years", value: 6 },
      { label: "81-above  Years", value: 7 },],
      criminal: '',
      visitortype: '',
      visitortypeList: [{ label: "Tourist", value: 1 },
      { label: "Work Traveller", value: 2 },
      { label: "Student", value: 3 },],
    };
  },
  methods: {
    voterFilterModalOpen() {
      this.voterfiltermodalsflag = true;
    },
    voterFilterModalClose() {
      this.voterfiltermodalsflag = false;
    },
    VisitorDomesticDetailsModalOpen() {
      this.viewvisitordomesticstatus = true;
    },
    VisitorDomesticDetailsModalClose() {
      this.viewvisitordomesticstatus = false;
    },
    VisitorInternationalDetailsModalOpen() {
      this.viewvisitorinternationalstatus = true;
    },
    VisitorInternationalDetailsModalClose() {
      this.viewvisitorinternationalstatus = false;
    },
    redirectdynvisitor() {
      this.$router.push("/visitor/totallist");
    },
  },
};
</script>
<style scoped>
.company-header-box-outer {
  padding: 9px 16px;
  border: 1px solid #e0e5ed;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 0;
}

.company-legal-name {
  font-family: "AcuminPro-SemiBold";
  letter-spacing: 0px;
  color: #2b2e38;
  font-size: 16px;
  line-height: 20px;
  white-space: break-spaces;
}

.company-detail-box-outer {
  padding: 16px 16px 0;
  margin-bottom: 15px;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
}

.branch-detail-box-outer {
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
}

.branch-detail-box-outer .branch-row-box {
  padding: 16px;
  border-bottom: 1px solid #e0e5ed;
}

.branch-detail-box-outer .branch-row-box:last-child {
  border-bottom: 0;
}

.customer-label-group {
  margin-bottom: 20px;
}

.form-label-header {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #2b2e38;
  line-height: 16px;
}

.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}

.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}

.company-section-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
}

.company-section-outer::-webkit-scrollbar {
  display: block;
}

.company-section-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.company-section-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}

.custom-outline-whatsapp-btn {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 5px 6px;
  line-height: 10px;
}

.custom-outline-whatsapp-btn .whatsapp-color {
  color: #2ca270;
  font-size: 18px;
}

.custom-outline-call-btn {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 7.5px;
  line-height: 10px;
}

.custom-outline-call-btn .call-btn-color {
  color: #2ca270;
  font-size: 13px;
}

.custom-outline-ellipsis-btn {
  padding: 0;
  border: 0;
}

.text-star-blank {
  color: #c1c5c9;
}

.feedback-chart-outer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
}

.feedback-chart-outer .chart-label {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #4a5463;
  text-align: left;
  line-height: 16px;
  padding: 16px;
}

.voter-exit-box-outer {
  padding: 16px 16px 0;
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
}

.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}

.customer-label-group {
  margin-bottom: 16px;
}

.border-guest-domestic {
  border: 1px solid #64a137;
}

.border-guest-international {
  border: 1px solid #059abd;
}
</style>